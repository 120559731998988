import { Box, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

import Container from "../../components/Container";

import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "../../hooks";
import { LoadingBackdrop } from "../../layouts";
import { userState } from "../../redux/reducers/userReducer";
import { getUrlForBookings, getUrlForUsers } from "../../utils/confighelpers";

export default function BusinessProfileUpSubscriptionSuccess({ navigation }) {
  const theme = useTheme();
  const location = useLocation();
  const [userToken] = useLocalStorage("userToken");
  const userReduxState = useSelector(userState);
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const [loading, setLoading] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [subscriptionPlan, setSubscriptionPlan] = React.useState([]);
  const initialTime = moment();

  const getUserInfo = async () => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${getUrlForUsers()}getUserInfo`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const request = await axios(config);
    if (request.data.statusCode === 200) {
      console.log("data", request.data.data);
      const userData = request.data.data;

      /* if (userData.onBoardingStep === 0) {
      } else if (userData.onBoardingStep === 1) {
      } else if (userData.onBoardingStep === 2) {

      } else  */if (userData.onBoardingStep === 1) {
        // navigation("/businessprofilesetup/done");
        navigation("/businessprofilesetup");
      } else if (userData.onBoardingStep === 3) {
        navigation("/businessprofilesetup/done");
      } else {
        var duration = moment.duration(moment(initialTime, 'YYYY-MM-DD h:mm A').diff(moment()));
        var minutes = duration.asMinutes();
        console.log("minutes", minutes);

        if (minutes <= 5) {
          var timer = setTimeout(async function () {
            await getUserInfo();
            // setLoading(false);
            // clearInterval(timer);
            // clearInterval(timeInterval);
          }, 5000);
        } else {
          setLoading(false);
        }
      }
    }
  }

  const checkStripeSubscriptionCheckoutStatus = async () => {
    setLoading(true);
    const config = {
      method: "post",
      url: `${getUrlForBookings()}payments/checkStripeSubscriptionCheckoutStatus`,
      data: {
        session_id: searchParams.get("session_id")
      },
      headers: {
        "x-access-token": userToken,
      },
    };
    const request = await axios(config);
    if (request.data.statusCode === 200) {
      console.log("data", request.data.data);
      const checkoutStatusData = request.data.data;

      if (checkoutStatusData.checkoutSessionStatus === "active") {
        // navigation("/businessprofilesetup/done");
        if (userReduxState.businessType === "service") {
          navigation("/myresource/add", {
            state: {
              ...location.state,
              ...userReduxState.userData,
              from: "registration", 
              sentFromVerifyEmail: true,
            },
          })
        } else {
          navigation("/businessprofilesetup/done");
        }
      } /* else if (checkoutStatusData.checkoutSessionStatus === "active") {
        navigation("/businessprofilesetup/done");
      }  */else {
        var duration = moment.duration(moment(initialTime, 'YYYY-MM-DD h:mm A').diff(moment()));
        var minutes = duration.asMinutes();
        console.log("minutes", minutes);

        if (minutes <= 5) {
          var timer = setTimeout(async function () {
            await checkStripeSubscriptionCheckoutStatus();
            // setLoading(false);
            // clearInterval(timer);
            // clearInterval(timeInterval);
          }, 5000);
        } else {
          setLoading(false);
        }
      }
    }
  }

  React.useEffect(() => {
    setLoading(true);
    setTimeout(async function () {
      if (userReduxState.businessType === "event") {
        await getUserInfo();
      } else {
        await checkStripeSubscriptionCheckoutStatus();
      }
    }, 2000);
  }, []);

  return (
    <LoadingBackdrop loading={loading}>
      <Box className="min-w-[1024px]">

        <Container className={"mx-32 lg:mx-28 sm:mx-0 md:mx-4"}>
          <Box
            id="subscriptionPlans"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "start",
              pt: 5,
              gap: 5,
            }}>
            {loading === false && (<Button
              sx={{
                color: "common.white",
                backgroundColor: "secondary.mainLight",
                boxShadow: "none",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "secondary.mainLight",
                  boxShadow: "none",
                },
                fontWeight: 600,
                borderRadius: 3,
                fontSize: 16,
                mt: 3,
              }}
              variant={"contained"}
              onClick={() => {
                // updateUserSubscription(d._id);
              }}>
              Back To Home
            </Button>)}
          </Box>
        </Container>
      </Box>
    </LoadingBackdrop>
  );
}
