import { Box, Button, FormHelperText, IconButton, InputAdornment, InputBase, MenuItem, Select, Stack, ToggleButton, ToggleButtonGroup, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import cogoToast from "cogo-toast";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Container from "../../components/Container";
import { useLocalStorage } from "../../hooks";
import { LoadingBackdrop } from "../../layouts";
import * as schoolActions from "../../redux/reducers/schoolReducer";
import * as userActions from "../../redux/reducers/userReducer";
import { userState } from "../../redux/reducers/userReducer";
import BusinessProfileForm from "./BusinessProfileForm";
import BusinessProfileImgInput from "./BusinessProfileImgInput";

import select_start_adornment from "../../assets/selectAdornment.svg";

import facebook_img from "../../assets/Facebook F.png";
import instagram_img from "../../assets/Instagram.png";
import linkedIn_img from "../../assets/LinkedIn 2.png";
import progressdone from "../../assets/progressdone.svg";
import twitter_img from "../../assets/Twitter.png";
import youtube_img from "../../assets/YouTube.png";
import { Remove } from "@mui/icons-material";
import { getUrlForStage, getUrlForUsers } from "../../utils/confighelpers";
import OnBoardingHeader from "./OnBoardingHeader";

export default function BusinessProfileSetup({ navigation }) {
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const userReduxState = useSelector(userState);
  const [userToken] = useLocalStorage("userToken");
  const [onBoardingState, setOnBoardingState] = React.useState(
    userReduxState && userReduxState.onBoardingData && Object.keys(userReduxState.onBoardingData).length > 0 ? userReduxState.onBoardingData : (location.state !== null ? location.state : null)
  );
  const [catsSelected, setCatsSelected] = React.useState(
    onBoardingState !== null && onBoardingState?.categories ? onBoardingState.categories : []
  );
  const [contactAddress, setContactAddress] = React.useState(
    // onBoardingState !== null ? onBoardingState.address : ""
    ""
  );
  const [postAddressConfig, setPostAddressConfig] = React.useState();
  const [loading, setLoading] = React.useState(false);
  // const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  // const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const isMobile = false;
  const isTablet = false;
  const formik = useFormik({
    initialValues: {
      socialType: onBoardingState?.socialType
        ? onBoardingState.socialType
        : "normal",
      categories: onBoardingState !== null && onBoardingState?.categories ? onBoardingState.categories : [],
      businessTypes:
        onBoardingState !== null && onBoardingState?.businessTypes ? onBoardingState.businessTypes : {},
      businessProfileType:
        onBoardingState !== null && onBoardingState?.businessProfileType
          ? onBoardingState.businessProfileType
          : "individual", //business
      logo: onBoardingState !== null && onBoardingState?.logo ? onBoardingState.logo : "",
      coverPhoto: onBoardingState !== null && onBoardingState?.coverPhoto ? onBoardingState.coverPhoto : "",
      instituteName:

        onBoardingState !== null ?
          onBoardingState?.socialType && onBoardingState?.name ?
            onBoardingState?.name
            : onBoardingState?.instituteName ? onBoardingState.instituteName : ""
          : "",
      // contactAddress: onBoardingState !== null ? onBoardingState.contactAddress : "",
      contactAddress: "",
      contactNumber:
        onBoardingState !== null
          ? onBoardingState?.socialType && onBoardingState.socialType === "normal" && onBoardingState?.phoneNumber
            ? onBoardingState.phoneNumber
            : (onBoardingState?.contactNumber || "")
          : "",
      countryName: onBoardingState !== null && onBoardingState?.countryName ? onBoardingState.countryName : "nl",
      countryCode: onBoardingState !== null && onBoardingState?.countryCode ? onBoardingState.countryCode : "+31",
      // countryCode: "",
      phone: onBoardingState !== null
        ? onBoardingState?.socialType && onBoardingState.socialType === "normal" && onBoardingState?.phoneNumber
          ? onBoardingState.phoneNumber
          : (onBoardingState?.contactNumber || "")
        : "",
      formattedPhoneNumber: onBoardingState !== null ?
        onBoardingState?.socialType && onBoardingState.socialType === "normal" && onBoardingState?.phoneNumber ? onBoardingState?.countryCode + " " + onBoardingState?.phoneNumber
          : onBoardingState?.formattedPhoneNumber ? onBoardingState.formattedPhoneNumber : "+31"
        : "",
      contactEmail:
        onBoardingState !== null
          ? onBoardingState?.socialType && onBoardingState.socialType !== "normal"
            ? onBoardingState?.username || onBoardingState?.email || ""
            : (onBoardingState?.contactEmail || "")
          : "",
      website: onBoardingState !== null && onBoardingState?.website ? onBoardingState.website : "",
      aboutInstitute:
        onBoardingState !== null && onBoardingState?.aboutInstitute ? onBoardingState.aboutInstitute : "",
      socialLinksSelected: [],
      socialLink: {
        facebook: onBoardingState !== null && onBoardingState?.socialLink?.facebook ? onBoardingState?.socialLink?.facebook : "",
        instagram: onBoardingState !== null && onBoardingState?.socialLink?.instagram ? onBoardingState?.socialLink?.instagram : "",
        youtube: onBoardingState !== null && onBoardingState?.socialLink?.youtube ? onBoardingState?.socialLink?.youtube : "",
        linkedIn: onBoardingState !== null && onBoardingState?.socialLink?.linkedIn ? onBoardingState?.socialLink?.linkedIn : "",
        twitter: onBoardingState !== null && onBoardingState?.socialLink?.twitter ? onBoardingState?.socialLink?.twitter : "",
      }
    },
    // enableReinitialize: true,
    validate: (values) => {
      const errors = {};
      // if (Object.keys(values.businessTypes).length === 0) {
      //   errors.businessTypes = "Required!";
      // }
      if (!values.instituteName) {
        errors.instituteName = "Required!";
      }
      if (!values.contactAddress) {
        errors.contactAddress = "Required!";
      }
      // if (!values.contactNumber) {
      if (!values.phone) {
        errors.contactNumber = "Required!";
      } else if (values.phone.length > 0 && (values.phone.length < 8 || values.phone.length > 10)) {
        errors.contactNumber = "Please enter valid phone number";
      }
      if (!values.contactEmail) {
        errors.contactEmail = "Required!";
      } else if (values.contactEmail.length > 0 && checkSocialUrl("email", values.contactEmail) === false) {
        errors.contactEmail = "Please enter valid email";
      }
      if (!values.aboutInstitute) {
        errors.aboutInstitute = "Required!";
      } else if (values.aboutInstitute.length < 5) {
        errors.aboutInstitute = `Enter at least 5 letters to explain your ${values.instituteName}`;
      }

      if (values.website.length > 0 && isUrlValid(values.website) === false) {
        errors.website = "Please enter valid website URL";
      }

      if (values.socialLink.facebook.length > 0 && (isUrlValid(values.socialLink.facebook) === false || checkSocialUrl("facebook", values.socialLink.facebook) === false)) {
        errors.facebook = "Please enter valid facebook URL";
      }

      if (values.socialLink.instagram.length > 0 && (isUrlValid(values.socialLink.instagram) === false || checkSocialUrl("instagram", values.socialLink.instagram) === false)) {
        errors.instagram = "Please enter valid instagram URL";
      }

      if (values.socialLink.youtube.length > 0 && (isUrlValid(values.socialLink.youtube) === false || checkSocialUrl("youtube", values.socialLink.youtube) === false)) {
        errors.youtube = "Please enter valid youtube URL";
      }

      if (values.socialLink.linkedIn.length > 0 && (isUrlValid(values.socialLink.linkedIn) === false || checkSocialUrl("linkedIn", values.socialLink.linkedIn) === false)) {
        errors.linkedIn = "Please enter valid linkedIn URL";
      }

      if (values.socialLink.twitter.length > 0 && (isUrlValid(values.socialLink.twitter) === false || checkSocialUrl("twitter", values.socialLink.twitter) === false)) {
        errors.twitter = "Please enter valid twitter URL";
      }

      // if (!values.coverPhoto) {
      //   errors.coverPhoto = "Required!";
      // }
      // if (Array.isArray(values.categories) && !values.categories?.length) {
      //   errors.categories = "Required!";
      // }
      /* if (Object.keys(errors).length === 0) {
        formik.setSubmitting(false);
      } */
      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      // console.log("values sadasdas", values);
      // return;
      setLoading(true);
      // cogoToast.loading("Loading...");
      const body1 = {
        seletedCategories: values.categories,
      };
      const config1 = {
        method: "put",
        url: `${getUrlForUsers()}updateProfile`,
        headers: {
          "x-access-token": userToken.toString(),
          "Access-Control-Allow-Headers": "*",
        },
        data: body1,
      };
      const locationObj = {};
      /* const addLocationRequest = await axios(postAddressConfig);
      if (addLocationRequest.data.statusCode !== 200) {
        if (addLocationRequest.data.statusCode === 500) {
          cogoToast.error(addLocationRequest.data.message);
          setSubmitting(false);
          setLoading(false);
          return;
        }
        cogoToast.error("Error adding location.");
        setSubmitting(false);
        setLoading(false);
        return;
      }
      const locationObj = addLocationRequest.data.data; */
      const requestCategories = await axios(config1);
      if (requestCategories.status === 200) {
        dispatch(
          schoolActions.setCategories(
            requestCategories.data.data.seletedCategories
          )
        );
        // if (
        //   onBoardingState?.socialType &&
        //   onBoardingState.socialType !== "normal"
        // ) {
        //   let locationObj = {};
        //   const addLocationRequest = await axios(postAddressConfig);
        //   if (addLocationRequest.data.statusCode !== 200) {
        //     if (addLocationRequest.data.statusCode === 500) {
        //       cogoToast.error(addLocationRequest.data.message);
        //       setSubmitting(false);
        //       setLoading(false);
        //       return;
        //     }
        //     cogoToast.error("Error adding location.");
        //     setSubmitting(false);
        //     setLoading(false);
        //     return;
        //   } else {
        //     locationObj = addLocationRequest.data.data;
        //   }

        //   const body2 = {
        //     schoolName: values.instituteName,
        //     businessTypes: values.businessTypes,
        //     businessProfileType: values.businessProfileType,
        //     logo: values.logo,
        //     images: [values.coverPhoto],
        //     about: values.aboutInstitute,
        //     locationObj,
        //     typeOfBusiness: "",
        //     // phoneNumber: onBoardingState !== null && onBoardingState?.socialType && onBoardingState.socialType === "normal" ? onBoardingState?.username : "",
        //     workNumber: onBoardingState !== null && onBoardingState?.socialType && onBoardingState.socialType === "normal" && onBoardingState?.phoneNumber
        //       ? onBoardingState.phoneNumber
        //       : values.contactNumber,
        //     workEmail: onBoardingState !== null && onBoardingState?.socialType && onBoardingState.socialType !== "normal"
        //       ? onBoardingState.username
        //       : values.contactEmail,
        //     workWebsite: values.website,
        //     selectedView: userReduxState.businessType,
        //     socialLink: values?.socialLink,
        //     // socialLink: {
        //     //   facebook: values?.socialLink?.facebook || "",
        //     //   instagram: values?.socialLink?.instagram || "",
        //     //   youtube: values?.socialLink?.youtube || "",
        //     //   linkedIn: values?.socialLink?.linkedIn || "",
        //     //   twitter: values?.socialLink?.twitter || "",
        //     // },
        //   };
        //   const config2 = {
        //     method: "post",
        //     url: `${getUrlForStage()}schools/addSchoolInformaton`,
        //     headers: {
        //       "x-access-token": userToken.toString(),
        //     },
        //     data: body2,
        //   };
        //   const requestAddSchoolInfo = await axios(config2);
        //   if (requestAddSchoolInfo.data.statusCode === 500) {
        //     cogoToast.error(requestAddSchoolInfo.data.message).then(() => {
        //       setSubmitting(false);
        //       setLoading(false);
        //       navigation(-1);
        //       return;
        //     });
        //   } else if (requestAddSchoolInfo.data.statusCode === 200) {
        //     const requestAccountStepUp = await axios.put(
        //       `${getUrlForUsers()}updateProfile`,
        //       { onBoardingStep: 2 },
        //       { headers: { "x-access-token": userToken } }
        //     );
        //     setLoading(false);
        //     if (requestAccountStepUp.status === 200) {
        //       if (requestAccountStepUp.data.statusCode === 200) {
        //         dispatch(
        //           schoolActions.setName(
        //             requestAddSchoolInfo.data.data.schoolName
        //           )
        //         );
        //         dispatch(
        //           schoolActions.setLogo(requestAddSchoolInfo.data.data.logo)
        //         );
        //         cogoToast.success("Success!").then(() => {
        //           // navigation("/schoolprofilesetup/done", {
        //           let nextUrl = "/businessprofilesetup/done"
        //           if (userReduxState.businessType === "service") {
        //             nextUrl = "/myvenue/add"
        //           }
        //           navigation(nextUrl, {
        //             state: {
        //               sentFromVerifyEmail: true,
        //               address: locationObj
        //             },
        //           })
        //         }
        //         );
        //       }
        //     }
        //   }
        // } else 
        {
          const body2 = onBoardingState !== null && onBoardingState?.socialType && onBoardingState.socialType !== "normal" ? {
            countryCode: values.countryCode,
            phoneNumber: values.phone,
            // phoneNumber: values.contactNumber,
          } : {
            email: values.contactEmail,
          };
          const config2 = {
            method: "put",
            url: `${getUrlForUsers()}updateProfile`,
            headers: {
              "x-access-token": userToken.toString(),
              "Access-Control-Allow-Headers": "*",
            },
            data: body2,
          };
          // setLoading(false);
          const requestEmail = await axios(config2);
          if (requestEmail.status === 200 && requestEmail.data.statusCode === 200) {
            if (requestEmail.data.statusCode === 403) {
              cogoToast.error(requestEmail.data.message);
              setSubmitting(false);
              setLoading(false);
              return;
            }
            setLoading(false);
            // cogoToast.success("Success!");
            setSubmitting(false);
            let verifyUrl = "../businessprofilesetup/verifyemail";
            if (
              onBoardingState?.socialType &&
              onBoardingState.socialType !== "normal"
            ) {
              verifyUrl = "../businessprofilesetup/verifynumber";
            }
            dispatch(userActions.setOnBoardingData(
              {
                ...userReduxState && userReduxState.onBoardingData && Object.keys(userReduxState.onBoardingData).length > 0 ? userReduxState.onBoardingData : {},
                ...values,
                logo: values.logo,
                coverPhoto: values.coverPhoto,
                categories: values.categories,
                businessTypes: values.businessTypes,
                businessProfileType: values.businessProfileType,
                name: values.instituteName,
                instituteName: values.instituteName,
                // contactNumber: values.contactNumber,
                contactNumber: values.formattedPhoneNumber,

                countryName: values.countryName,
                countryCode: values.countryCode,
                phone: values.phone,
                formattedPhoneNumber: values.formattedPhoneNumber,

                email: values.contactEmail,
                address: contactAddress,
                // locationObj,
                website: values.website,
                about: values.aboutInstitute,
                aboutInstitute: values.aboutInstitute,
                socialLink: values.socialLink,
                response: requestEmail.data,
                postAddressConfig,
              },
            ));
            navigation(verifyUrl, {
              state: {
                ...values,
                logo: values.logo,
                coverPhoto: values.coverPhoto,
                categories: values.categories,
                businessTypes: values.businessTypes,
                businessProfileType: values.businessProfileType,
                name: values.instituteName,
                instituteName: values.instituteName,
                // contactNumber: values.contactNumber,
                contactNumber: values.formattedPhoneNumber,

                countryName: values.countryName,
                countryCode: values.countryCode,
                phone: values.phone,
                formattedPhoneNumber: values.formattedPhoneNumber,

                email: values.contactEmail,
                address: contactAddress,
                // locationObj,
                website: values.website,
                about: values.aboutInstitute,
                aboutInstitute: values.aboutInstitute,
                socialLink: values.socialLink,
                response: requestEmail.data,
                postAddressConfig,
              },
            });
          } else {
            setSubmitting(false);
            setLoading(false);
          }
        }
      } else {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });
  const [businessTypeOpts, setBusinessTypeOpts] = React.useState([
    /* {
    _id: "",
    name: "Abhijit",
  } */
  ]);

  const [socialLinksSelected, setSocialLinksSelected] = React.useState([]);
  const [defaultSocialLinks, setDefaultSocialLinks] = React.useState(
    [
      {
        name: "facebook",
        icon: facebook_img,
        placeholder: "facebook.com/mypage"
      },
      {
        name: "instagram",
        icon: instagram_img,
        placeholder: "instagram.com/mypage"
      },
      {
        name: "linkedIn",
        icon: linkedIn_img,
        placeholder: "linkedin.com/in/myprofile"
      },
      {
        name: "twitter",
        icon: twitter_img,
        placeholder: "twitter.com/mypage"
      },
      {
        name: "youtube",
        icon: youtube_img,
        placeholder: "youtube.com/mychannel"
      }]);

  function isUrlValid(str) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    return pattern.test(str);
  }
  function checkSocialUrl(profile, str) {
    let regexp = {
      email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/,
      facebook: /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i,
      instagram: /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_]+)/im,
      youtube: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/img,
      linkedIn: /^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/gim,
      twitter: /^(https?:\/\/)?((w{3}\.)?)twitter\.com\/(#!\/)?[a-z0-9_]+$/i,
    }

    const pattern = new RegExp(regexp[profile]);
    return pattern.test(str);
  }

  const formInputStyle = {
    m: isMobile ? "0.25rem" : isTablet ? "0.5rem" : 1,
    p: isMobile ? "0.35rem" : isTablet ? "0.65rem" : 2,
    borderRadius: 3,
    border: 1,
    borderColor: "#EBEBEB",
    fontSize: isMobile ? 14 : isTablet ? 13 : 17,
    minWidth: isMobile ? 290 : isTablet ? 340 : 620,
    maxWidth: isMobile ? 290 : isTablet ? 340 : 620,
    "&:hover, &.Mui-focused": {
      border: 1,
      borderColor: "#7E00DD"
    },
    '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
      borderColor: 'rgba(0, 0, 0, 0)',
    },
  };

  React.useEffect(() => {
    const { setFieldValue } = formik;
    setFieldValue("categories", catsSelected);
    setFieldValue("contactAddress", contactAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catsSelected, contactAddress]);

  React.useEffect(() => {

    const socialArray = [];
    if (onBoardingState?.socialLink?.facebook) {
      socialArray.push("facebook");
    }
    if (onBoardingState?.socialLink?.instagram) {
      socialArray.push("instagram");
    }
    if (onBoardingState?.socialLink?.linkedIn) {
      socialArray.push("linkedIn");
    }
    if (onBoardingState?.socialLink?.twitter) {
      socialArray.push("twitter");
    }
    if (onBoardingState?.socialLink?.youtube) {
      socialArray.push("youtube");
    }
    // if (onBoardingState?.address) {
    //   setContactAddress(onBoardingState?.address);
    // }


    // setAddress({ ...dashboardReduxState.schoolData.locationObj });

    setSocialLinksSelected([...socialArray]);
    formik.setFieldValue("socialLinksSelected", socialArray);
  }, [onBoardingState])

  // React.useEffect(() => {
  //   (async () => {
  //     const config = {
  //       method: "get",
  //       url: `${getUrlForStage()}users/getBusinessTypes`,
  //       headers: {
  //         "x-access-token": userToken.toString(),
  //         "Access-Control-Allow-Headers": "*",
  //       },
  //     };
  //     const request = await axios(config);
  //     setBusinessTypeOpts(request.data.data);
  //   })();
  // }, []);

  if (onBoardingState === null) {
    navigation("/");
  }

  return (
    <LoadingBackdrop loading={loading}>
      <Box className="min-w-[1024px]">
        {/* <div
          className={
            "h-16 md:h-12 shadow-sm shadow-neutral-200 sm:flex sm:flex-row sm:justify-center sm:items-center"
          }
        >
          <h4
            className={
              "my-8 sm:my-0 md:my-6 mx-32 sm:mx-0 md:mx-16 font-bold text-4xl sm:text-2xl md:text-2xl"
            }
            sx={{ color: "#481B3A" }}
          >
            Business Profile
          </h4>
        </div> */}
        <OnBoardingHeader
          title={"Business Profile"}
          logoutCallBack={() => { }}
        />
        <Container className={"mx-32 lg:mx-28 sm:mx-0 md:mx-4"}>
          <Box sx={{ my: 5 }}>
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>

              <Box
                className={
                  "grid grid-cols-2 gap-8 sm:gap-4 md:gap-4 mx-auto max-w-7xl"
                }
              >
                <FormikProvider value={formik}>
                  <Box className={"flex flex-col"}>
                    {/* <Select
                      disableUnderline
                      displayEmpty
                      MenuProps={{
                        sx: {
                          "& .MuiMenu-paper": {
                            border: 1,
                            borderColor: "#424242",
                            borderRadius: 3,
                            mt: 0.5,
                          },
                        },
                      }}
                      renderValue={(value) => (
                        <div className={"flex flex-row items-center gap-3"}>
                          <img src={select_start_adornment} alt={""} />
                          {value !== undefined ? (
                            value.name
                          ) : (
                            <span className={"font-light text-plain-gray"}>
                              Choose what describes your business
                            </span>
                          )}
                        </div>
                      )}
                      value={formik.businessTypes}
                      onChange={(event) => {
                        formik.setFieldValue("businessTypes", event.target.value);
                      }}
                      sx={{
                        borderRadius: 3,
                        maxWidth: 696,
                        backgroundColor: "common.white",
                        border: 1,
                        borderColor: "#EBEBEB",
                        "&:hover, &.Mui-focused": {
                          border: 1,
                          borderColor: "#7E00DD",
                        },
                        ".MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderWidth: 0,
                          borderColor: "rgba(0, 0, 0, 0)",
                        },
                      }}
                      className={"my-2"}
                    >
                      {businessTypeOpts.map((category, idx) => (
                        <MenuItem value={category} key={idx}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <BusinessProfileImgInput
                      setFieldValue={formik.setFieldValue}
                      values={formik.values}
                    />
                    <InputBase
                      name={"aboutInstitute"}
                      value={formik.values.aboutInstitute}
                      multiline
                      placeholder={
                        "Write a few lines about your self, your events, experiences your customers will have while attending your events and courses..."
                      }
                      onChange={(event) => {
                        formik.setFieldValue("aboutInstitute", event.target.value);
                      }}
                      sx={{
                        ...formInputStyle,
                        minWidth: "auto",
                        minHeight: 148,
                        maxHeight: 148,
                        overflowY: "auto",
                        flexWrap: "wrap",
                        borderRadius: 7,
                      }}
                      size={isMobile || isTablet ? "small" : "medium"}
                      className={`${formik.errors?.aboutInstitute && formik.touched?.aboutInstitute ? "!border-red-500" : ""}`}
                    />
                    <FormHelperText className="px-3 !text-red-600">
                      <ErrorMessage name="aboutInstitute" />
                    </FormHelperText>
                    <div className={"mt-2"}>
                      <h4 className={"font-medium text-brown-coffee text-lg"}>
                        Link to Social Media (Optional)
                      </h4>
                      <Box className="flex flex-row gap-2 mt-5">
                        <ToggleButtonGroup
                          value={socialLinksSelected}
                          onChange={(event, newFormats) => {
                            formik.setFieldValue("socialLinksSelected", newFormats);
                            // formik.setFieldValue("socialLink", newFormats);
                            setSocialLinksSelected(newFormats);
                          }}
                          aria-label="text alignment"
                        >
                          {defaultSocialLinks.map((data, i) => {
                            return (
                              <ToggleButton
                                key={i}
                                value={data.name}
                                className="rounded-full"
                                style={{
                                  marginRight: "10px",
                                  width: "65px",
                                  height: "65px",
                                  border: "1px solid",
                                  borderRadius: "50%",
                                  borderColor: "#EBEBEB",
                                  backgroundColor: (formik.values.socialLinksSelected.indexOf(data.name) !== -1)
                                    ? "#0000000A"
                                    : "#FFFFFF",
                                }}
                              >
                                <div className={"indicator cursor-pointer"} >
                                  <img className={"w-6"} src={data.icon} alt={""} />
                                  {(formik.values.socialLinksSelected.indexOf(data.name) !== -1) ? (<img className={"w-20 indicator-item border-0 p-0 badge badge-lg -translate-y-[1.5rem] translate-x-[-1.5rem]"} src={progressdone} alt={""} />) : ""}

                                </div>
                                {/* <IconTint
                                  maxWidth={26}
                                  maxHeight={26}
                                  src={data.icon}
                                /> */}
                              </ToggleButton>
                            );
                          })}
                        </ToggleButtonGroup>
                      </Box>
                      <Box>
                        <Stack direction={"column"} spacing={1} sx={{ my: 2, mr: 2 }}>
                          {defaultSocialLinks.map((data, i) => {
                            return (
                              formik.values.socialLinksSelected.indexOf(data.name) !== -1 && (
                                <Box key={i}>
                                  <Stack direction={"row"} spacing={3} alignItems={"center"} key={i}>
                                    <InputBase
                                      // component={InputBase}
                                      name={"socialLink.facebook"}
                                      value={formik.values.socialLink[data.name]}
                                      placeholder={data.placeholder}
                                      // classes={formInputClasses}
                                      sx={{
                                        ...formInputStyle,
                                        minWidth: "auto",
                                      }}
                                      startAdornment={
                                        <InputAdornment position={"start"}>
                                          <img className={"w-8"} src={data.icon} alt={""} />
                                        </InputAdornment>
                                      }
                                      fullWidth
                                      onChange={(event) => {
                                        formik.setFieldValue("socialLink." + data.name, event.target.value);
                                      }}
                                      // className={`${formik.errors?.[data.name] && formik.touched?.[data.name] ? "!border-red-500" : ""}`}
                                      className={`${formik.errors?.[data.name] ? "!border-red-500" : ""}`}
                                    />
                                    <IconButton
                                      sx={{
                                        backgroundColor: "secondary.main",
                                        height: 20,
                                        width: 20,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      onClick={() => {
                                        let linksSelected = [...formik.values.socialLinksSelected];
                                        linksSelected = linksSelected.filter(
                                          (link) => link !== data.name
                                        );
                                        formik.setFieldValue("socialLinksSelected", linksSelected);
                                        formik.setFieldValue("socialLink." + data.name, "");
                                      }}
                                    >
                                      <Remove sx={{ color: "common.white", width: 16 }} />
                                    </IconButton>
                                  </Stack>
                                  <FormHelperText className="px-3 !text-red-600">
                                    &nbsp;
                                    {formik.errors?.[data.name]}
                                  </FormHelperText>
                                </Box>
                              )
                            );
                          })}
                        </Stack>
                      </Box>
                    </div>
                  </Box>
                </FormikProvider>
                <BusinessProfileForm
                  form={formik}
                  formErros={formik.errors}
                  contactAddress={contactAddress}
                  setContactAddress={setContactAddress}
                  setPostAddressConfig={setPostAddressConfig}
                />
              </Box>
              <Box
                className={
                  "grid grid-cols-1 grid-flow-col max-w-[83.5rem] mx-auto justify-items-end sm:justify-items-center md:justify-items-center"
                }
              >
                <Button
                  sx={{
                    backgroundColor: "#481B3A",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#481B3A",
                    },
                    fontWeight: "bold",
                    width: isTablet ? 265 : 195,
                    height: isMobile || isTablet ? 55 : 65,
                    fontSize: isMobile || isTablet ? 16 : 20,
                    borderRadius: 3,
                    my: 2,
                  }}
                  variant={"contained"}
                  disabled={
                    // !(formik.isValid && formik.dirty) && !formik.isSubmitting
                    formik.isSubmitting
                  }
                  onClick={formik.submitForm}
                >
                  Continue
                </Button>
              </Box>
            </form>
          </Box>
        </Container>
      </Box>
    </LoadingBackdrop>
  );
}
