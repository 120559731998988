import React from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

// import Class from "./modals/classes";
// import VerificationModal from "./modals/verificationModals/Verification";
import BookingDetails from "./modals/booking/BookingDetails";
import AddNewBusinessProfile from "./modals/businessProfile/action/AddNewBusinessProfile";
import EditBusinessProfileEvent from "./modals/businessProfile/action/EditBusinessProfileEvent";
import BusinessProfileUpComplete from "./modals/businessProfile/BusinessProfileComplete";
import EventDetails from "./modals/businessProfile/EventDetails";
import EventList from "./modals/businessProfile/EventList";
import BusinessProfileSetup from "./modals/businessProfile/index";
import ScheduleList from "./modals/businessProfile/ScheduleList";
import BusinessProfileSession from "./modals/businessProfile/view/BusinessProfileSession";
import Home from "./modals/dashboard/Home";
import ForgotPassword from "./modals/forgotPassword";
import ResetPassword from "./modals/forgotPassword/ResetPassword";
import VerifyForgotPassword from "./modals/forgotPassword/Verify";
import BusinessPaymentProfile from "./modals/paymentProfile/BusinessPaymentProfile";
import SchoolProfileSetup from "./modals/schoolProfile";
import SchoolProfileUpComplete from "./modals/schoolProfile/SchoolProfileComplete";
import EditBusinessProfile from "./modals/settings/businessProfile/EditBusinessProfile";
import ViewBusinessProfile from "./modals/settings/businessProfile/ViewBusinessProfile";
import EditMyProfile from "./modals/settings/myProfile/EditMyProfile";
import SignIn from "./modals/signin";
import SignUp from "./modals/signup";
import SignUpComplete from "./modals/signup/SignUpComplete";
import AddServices from "./modals/venueAndServices/services/action/AddServices";
import ServiceList from "./modals/venueAndServices/services/ServiceList";
import VerifyEmail from "./modals/verifyCredentials/VerifyEmail";
import VerifyNumber from "./modals/verifyCredentials/VerifyNumber";
import AddVenue from "./modals/venueAndServices/venue/AddVenue";
import BusinessTypes from "./modals/businessProfile/businessType/BusinessTypes";
import VenueList from "./modals/venueAndServices/venue/VenueList";
import ServiceScheduleList from "./modals/venueAndServices/services/ScheduleList";
import ServiceBookingDetails from "./modals/venueAndServices/services/booking/BookingDetails";
import BusinessProfileUpSubscription from "./modals/businessProfile/BusinessProfileUpSubscription";
import MyAvailableTime from "./modals/settings/myAvailableTime";
import MyAppProfile from "./modals/settings/myApps";
import ClassList from "./modals/classes/ClassList";
import ClassDetails from "./modals/classes/ClassDetails";
import AddNewClass from "./modals/classes/action/AddNewClass";
import ScheduleClassList from "./modals/classes/ScheduleClassList";
import SmallScreenNotAllow from "./modals/error/SmallScreenNotAllow";
import SessionPassDetail from "./modals/classes/sessionPass/SessionPassDetail";
import { useSelector } from "react-redux";
import { userState } from "./redux/reducers/userReducer";
import BusinessProfileClassSession from "./modals/classes/view/BusinessProfileSession";
import EventBookingDetails from "./modals/booking/EventBookingDetails";
import LocationList from "./modals/myLocation/LocationList";
import PurchaseDetails from "./modals/classes/sessionPass/PurchaseDetails";
import BusinessProfileUpSubscriptionSuccess from "./modals/businessProfile/BusinessProfileUpSubscriptionSuccess";
import MyResourceList from "./modals/venueAndServices/myresource/MyResourceList";
import AddMyResource from "./modals/venueAndServices/myresource/AddMyResource";
import ManageOnlineBooking from "./modals/settings/manageOnlineBooking";

export default function Router() {
  const navigate = useNavigate();
  const userReduxState = useSelector(userState);
  const routes = [
    {
      path: "/",
      element: <SignIn navigation={navigate} />,
    },
    {
      path: "/signup",
      element: <SignUp navigation={navigate} />,
    },
    {
      path: "/signup/verifynumber",
      element: <VerifyNumber navigation={navigate} />,
    },
    {
      path: "/signup/done",
      element: <SignUpComplete navigation={navigate} />,
    },
    {
      path: "/schoolprofilesetup",
      element: <SchoolProfileSetup navigation={navigate} />,
    },
    {
      path: "/schoolprofilesetup/verifyemail",
      element: <VerifyEmail navigation={navigate} />,
    },
    {
      path: "/schoolprofilesetup/done",
      element: <SchoolProfileUpComplete navigation={navigate} />,
    },
    {
      path: "/businesstype",
      element: <BusinessTypes navigation={navigate} />,
    },
    {
      path: "/businessprofilesetup",
      element: <BusinessProfileSetup navigation={navigate} />,
    },
    {
      path: "/businessprofilesetup/verifyemail",
      element: <VerifyEmail navigation={navigate} />,
    },
    {
      path: "/businessprofilesetup/verifynumber",
      element: <VerifyNumber navigation={navigate} />,
    },
    {
      path: "/businessprofilesetup/done",
      element: <BusinessProfileUpComplete navigation={navigate} />,
    },
    {
      path: "/businessprofilesetup/subscription",
      element: <BusinessProfileUpSubscription navigation={navigate} />,
    },
    {
      path: "/businessprofilesetup/subscription/success",
      element: <BusinessProfileUpSubscriptionSuccess navigation={navigate} landFor="success"/>,
    },
    {
      path: "/businessprofilesetup/subscription/cancel",
      element: <BusinessProfileUpSubscriptionSuccess navigation={navigate} landFor="cancel" />,
    },
    {
      path: "/myevents/new",
      element: (
        <AddNewBusinessProfile
          navigation={navigate}
          profileFor="event"
          title="Event"
        />
      ),
    },
    {
      path: "/myevents/update",
      element: (
        <EditBusinessProfileEvent
          navigation={navigate}
          profileFor="event"
          title="Event"
        />
      ),
    },
    {
      path: "/myevents",
      element: (
        <EventList navigation={navigate} profileFor="event" title="My Events" />
      ),
    },
    {
      path: "/myschedule",
      element: (
        <ScheduleList
          navigation={navigate}
          profileFor="event"
          // title="Schedule"
          title="My Events"
        />
      ),
    },
    {
      path: "/businessprofilesetup/class/new",
      element: (
        <AddNewBusinessProfile
          navigation={navigate}
          profileFor="class"
          title="Class"
        />
      ),
    },
    {
      path: "/myschedule/detail",
      element: userReduxState.businessType === "event" ? <BusinessProfileSession navigation={navigate} /> : <BusinessProfileClassSession navigation={navigate} />,
    },
    {
      path: "/myevents/detail",
      element: <EventDetails navigation={navigate} profileFor="event" />,
    },
    {
      path: "/businessprofile/view",
      element: <ViewBusinessProfile navigation={navigate} />,
    },
    {
      path: "/businessprofile/update",
      element: <EditBusinessProfile navigation={navigate} />,
    },
    {
      path: "/myprofile",
      element: <EditMyProfile navigation={navigate} />,
    },
    {
      path: "/home/dashboard",
      element: <Home navigation={navigate} />,
    },
    {
      path: "/myvenue",
      element: <VenueList navigation={navigate}
        profileFor="venue"
        title="My Venues" />,
    },
    {
      path: "/myvenue/add",
      element: <AddVenue navigation={navigate}
        profileFor="venue"
        title="My Venues" />,
    },
    {
      path: "/myvenue/update",
      element: <AddVenue navigation={navigate}
        profileFor="editvenue"
        title="Edit Venues" />,
    },
    {
      path: "/mylocation",
      element: <LocationList navigation={navigate}
        profileFor="location"
        title="My Location" />,
    },
    {
      path: "/ForgotPassword",
      element: <ForgotPassword navigation={navigate} />,
    },
    {
      path: "/ForgotPassword/Verify",
      element: <VerifyForgotPassword navigation={navigate} />,
    },
    {
      path: "/ForgotPassword/Reset",
      element: <ResetPassword navigation={navigate} />,
    },
    {
      path: "/payment/profile",
      element: <BusinessPaymentProfile navigation={navigate} />,
    },
    {
      path: "/booking/view",
      element: userReduxState.businessType === "event" ? <EventBookingDetails navigation={navigate} /> : <BookingDetails navigation={navigate} />,
    },
    {
      path: "/myservices",
      element: (
        <ServiceList
          navigation={navigate}
          profileFor="service"
          title="My Services"
        />
      ),
    },
    {
      path: "/myservices/new",
      element: (
        <AddServices
          navigation={navigate}
          profileFor="service"
          title="Add New Services"
        />
      ),
    },
    {
      path: "/myservices/update",
      element: (
        <AddServices
          navigation={navigate}
          profileFor="editservice"
          title="Edit Services"
        />
      ),
    },
    {
      path: "/myservices/schedule",
      element: (
        <ServiceScheduleList
          navigation={navigate}
          profileFor="service"
          title="Schedule"
        />
      ),
    },
    {
      path: "/myservices/schedule/detail",
      element: (
        <ServiceBookingDetails
          navigation={navigate}
          profileFor="service"
          title="Schedule"
        />
      ),
    },
    {
      path: "/myresource",
      element: <MyResourceList navigation={navigate}
        profileFor="myresource"
        title="My Resource" />,
    },
    {
      path: "/myresource/add",
      element: <AddMyResource navigation={navigate}
        profileFor="addmyresource"
        title="Add Resource" />,
    },
    {
      path: "/myresource/update",
      element: <AddMyResource navigation={navigate}
        profileFor="editmyresource"
        title="Edit Resource" />,
    },
    {
      path: "/my-available-time",
      element: (
        <MyAvailableTime
          navigation={navigate}
          title="My Available Time"
        />
      ),
    },
    {
      path: "/my-connected-apps",
      element: (
        <MyAppProfile
          navigation={navigate}
          title="My Available Time"
        />
      ),
    },
    {
      path: "/manage-online-booking",
      element: (
        <ManageOnlineBooking
          navigation={navigate}
          title="Manage Online Booking"
        />
      ),
    },
    {
      path: "/myclasses/new",
      element: (
        <AddNewClass
          navigation={navigate}
          profileFor="class"
          title="Classes"
        />
      ),
    },
    {
      path: "/myclasses/update",
      element: (
        <EditBusinessProfileEvent
          navigation={navigate}
          profileFor="class"
          title="Classes"
        />
      ),
    },
    {
      path: "/myclasses",
      element: (
        <ClassList navigation={navigate} profileFor="class" title="My Classes" />
      ),
    },
    {
      path: "/myclasses/detail",
      element: <ClassDetails navigation={navigate} profileFor="class" />,
    },
    {
      path: "/myclasses/myschedule",
      element: (
        <ScheduleClassList
          navigation={navigate}
          profileFor="class"
          title="Schedule"
        />
      ),
    },
    {
      path: "/small-screen-not-allow",
      element: (
        <SmallScreenNotAllow
          navigation={navigate}
          title="My Available Time"
        />
      ),
    },
    {
      path: "/class/sessionpasses/purchase",
      element: (
        <SessionPassDetail
          navigation={navigate}
          profileFor="class"
          title="Pass Detail"
        />
      ),
    },
    {
      path: "/purchase/view",
      element: <PurchaseDetails navigation={navigate} />,
    },
    {
      path: "*",
      element: <Navigate replace to="/home/dashboard" />,
    },
  ];
  return (
    <Routes>
      {routes.map((route, idx) => (
        <Route key={idx} {...route} />
      ))}
    </Routes>
  );
}
