import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    // businessType: "event",
    businessType: "",
    userData: {},
    onBoardingData: {},
    userPhone: "",
  },
  reducers: {
    setBusinessType: (state, action) => {
      state.businessType = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = { ...action.payload };
    },
    setUserPhone: (state, action) => {
      state.userPhone = { ...action.payload };
    },
    clearUserData: (state, action) => {
      // state.businessType = "event";
      state.businessType = "";
      state.userData = {};
      state.userPhone = {};
      state.onBoardingData = {};
    },
    setOnBoardingData: (state, action) => {
      state.onBoardingData = { ...action.payload };
    },
  },
});

export const { setBusinessType, setUserData, setUserPhone, clearUserData, setOnBoardingData } = userSlice.actions;

export const userState = (state) => state.user;

export default userSlice.reducer;
