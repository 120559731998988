import { Box, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import cogoToast from "cogo-toast";
import React from "react";
import { useLocation } from "react-router-dom";
import Container from "../../../components/Container";

import { useDispatch, useSelector } from "react-redux";
import bg from "../../../assets/bg.png";
import graph_png from "../../../assets/graph.png";
import { LoadingBackdrop } from "../../../layouts";
import * as userActions from "../../../redux/reducers/userReducer";
import { userState } from "../../../redux/reducers/userReducer";
import { useLocalStorage } from "../../../hooks";
import axios from "axios";
import { getUrlForUsers } from "../../../utils/confighelpers";
import OnBoardingHeader from "../OnBoardingHeader";
import BusinessProfileUpSubscription from "../BusinessProfileUpSubscription";

const BusinessTypes = ({ navigation }) => {
  const theme = useTheme();
  const location = useLocation();
  const userReduxState = useSelector(userState);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const [userToken] = useLocalStorage("userToken");
  const [selectDashbord, setSelectDashbord] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const cards = [
    {
      type: "class",
      title: "Bookzy Classes",
      data: "Choose this, if you host classes, workshops or other expierences",
      bgColor: "bg-portland-orange",
    },
    {
      type: "event",
      title: "Bookzy Events",
      data: "Choose this, if you host events, workshops, classes or other expierences",
      bgColor: "bg-light-brown-coffee",
    },
    {
      type: "service",
      title: "Bookzy Services",
      data: "Choose this, if you provide services like wellness massages, psysiotheraphy,beauty salon services",
      bgColor: "bg-portland-orange",
    },
  ];

  const updateUserProfile = async (businessType) => {
    // setLoading(true)
    dispatch(userActions.setBusinessType(businessType));
    const requestConfig = {
      method: "put",
      url: `${getUrlForUsers()}updateProfile`,
      headers: {
        "x-access-token": userToken,
      },
      data: {
        onBoardingStep: 2,
        businessType: businessType
      },
    };
    console.log("requestConfig", requestConfig);
    const response = await axios(requestConfig);
    if (response.status === 200) {
      if (response.data.statusCode === 403) {
      } else if (response.data.statusCode === 200) {
        console.log("data", response.data.data);
        dispatch(userActions.setOnBoardingData(
          {
            ...location.state,
            ...response.data.data,
            sentFromSignupComplete: true,
            socialType: location.state.socialType,
            username: location.state.username,
          },
        ));
        // // dispatch(userActions.setBusinessType(businessType));
        // // navigation("/home/dashboard", { replace: true });
        // navigation("/businessprofilesetup", {
        //   state: {
        //     ...location.state,
        //     sentFromSignupComplete: true,
        //     socialType: location.state.socialType,
        //     username: location.state.username,
        //   },
        // })
      }
    }
    setLoading(false)
  };

  console.log("location.state", location.state);
  return (
    <LoadingBackdrop loading={loading}>
      {/* <div
        className={
          "h-16 md:h-12 shadow-sm shadow-neutral-200 sm:flex sm:flex-row sm:justify-center sm:items-center"
        }
      >
        <h4
          className={
            "my-8 sm:my-0 md:my-6 mx-32 sm:mx-0 md:mx-16 font-bold text-4xl sm:text-2xl md:text-2xl"
          }
          sx={{ color: "#481B3A" }}
        >
          Choose Your business types
        </h4>
      </div> */}
      <OnBoardingHeader
        title={"Choose Your business types"}
        logoutCallBack={() => { }}
      />
      <Container
        className="h-screen"
        sx={{
          backgroundImage: `url('${bg}')`,
          backgroundRepeat: `no-repeat`,
          backgroundSize: `cover`,
        }}
      >
        <Box className={`w-full p-20 h-full `}>
          <div className="flex flex-col gap-1">
            <div
              className={`flex flex-row gap-8 sm:gap-2`}>

              <div
                // className={`flex flex-col gap-8 grid-cols-[330px_330px_330px] sm:gap-2`}
                className={`flex flex-none flex-col gap-8 sm:gap-2`}
              >
                {cards?.map(({ type, title, data, bgColor }, i) => (
                  <Box
                    key={i}
                    className={`min-h-48 left-40 max-w-sm max-w-md md:w-3/4 h-48 ${bgColor} rounded-3xl cursor-pointer border-4 hover:shadow-lg ${selectDashbord === type ? "border-[#7E00DD]" : "border-transparent"}`}
                    sx={{
                      backgroundImage: `url('${graph_png}')`,
                      backgroundRepeat: `no-repeat`,
                      backgroundSize: `cover`,
                    }}
                    onClick={async () => {
                      // cogoToast.success("Thank you for selecting bookzy " + type);
                      setSelectDashbord(type);
                      await updateUserProfile(type);
                    }}
                  >
                    <div
                      className={
                        // "grid grid-cols-3 px-6 py-4 gap-12 sm:gap-0 md:gap-10 lg:gap-2"
                        "grid grid-cols-1 p-6 gap-2 "
                      }
                    >
                      <h1
                        className={
                          "font-bold text-2xl sm:text-xl md:text-xl lg:text-xl text-white col-start-1 col-span-2"
                        }
                      >
                        {title}
                      </h1>
                      <span
                        className={
                          " col-start-1 col-span-2 w-full leading-4  bottom-12 mt-5 text-white font-light"
                        }
                      >
                        {data}
                      </span>
                    </div>
                  </Box>
                ))}
              </div>
              <div className="w-full flex-1  justify-center items-start">
                {selectDashbord !== "" && <BusinessProfileUpSubscription/>}
              </div>

            </div>
            <div className="mt-5 w-full flex justify-center align-bottom">
              {/* <Button
                sx={{
                  backgroundColor: "#481B3A",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#481B3A",
                  },
                  fontWeight: "bold",
                  width: isMobile ? 270 : isTablet ? 345 : 256,
                  height: isMobile ? 50 : isTablet ? 50 : 65,
                  fontSize: isMobile ? 15 : 20,
                  borderRadius: 3,
                  my: 2,
                  color: "white",
                }}
                onClick={async () => {
                  if (selectDashbord !== "") {
                    await updateUserProfile(selectDashbord)

                  } else {
                    cogoToast.error("Please choose your business types!");
                  }
                }}
              >
                Continue
              </Button> */}
            </div>
          </div>
        </Box>
      </Container>
    </LoadingBackdrop>
  );
};

export default BusinessTypes;
