import { Box, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

import Container from "../../components/Container";
import Logo from "../../components/Logo";

import undraw_vector from "../../assets/celebration_re_kc9k 1.png";
import { LoadingBackdrop } from "../../layouts";
import axios from "axios";
import { useLocalStorage } from "../../hooks";
import { useSelector } from "react-redux";
import { userState } from "../../redux/reducers/userReducer";
import { useLocation } from "react-router-dom";
import { getUrlForBookings, getUrlForUsers } from "../../utils/confighelpers";

export default function BusinessProfileUpSubscription({ navigation }) {
  const theme = useTheme();
  const location = useLocation();
  const [userToken] = useLocalStorage("userToken");
  const userReduxState = useSelector(userState);
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const [loading, setLoading] = React.useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = React.useState([]);
  const stripePricingTableId = {
    prod: {
      class: "prctbl_1PkNfRDx1lYwRllZPc6xn0SW", // stripe live account
      event: "prctbl_1PkNfRDx1lYwRllZPc6xn0SW", // stripe live account
      // service: "prctbl_1Pav5kDx1lYwRllZNELAunkE", // stripe live account // stripe test account [Bookzy Services solo]
      service: "prctbl_1QGzYwDx1lYwRllZ4oxUUC2E", // stripe live account // stripe test account [Bookzy Services solo & Plus]
    },
    dev: {
      class: "prctbl_1Pvvk0Dx1lYwRllZkfX9HKe9", // stripe test account
      event: "prctbl_1Pvvk0Dx1lYwRllZkfX9HKe9", // stripe test account
      // service: "prctbl_1Q28tvDx1lYwRllZBXIBxpG5", // stripe test account [Bookzy Services solo]
      service: "prctbl_1QE057Dx1lYwRllZZ1tupnOv", // stripe test account [Bookzy Services solo & Plus]
    }
  };


  const getSubscriptionPlan = async () => {
    // setLoading(true)
    const requestConfig = {
      method: "get",
      url: `${getUrlForUsers()}getSubscriptionPlans?listFor=${userReduxState.businessType}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const response = await axios(requestConfig);
    if (response.status === 200) {
      if (response.data.statusCode === 403) {
      } else if (response.data.statusCode === 200) {
        if (response.data.data) {
          setSubscriptionPlan(response.data.data)
          // if (((location?.state && !location.state.hasOwnProperty("origin")) || location.state?.origin !== "paymentprofile") && userReduxState.businessType === "event") {
          //   await Promise.all(response.data.data.map(async (d) => {
          //     if (d.typeOfSubscription === "free") {
          //       await updateUserSubscription(d._id, d);
          //     }
          //     return d;
          //   }));
          // }
        }
      }
    }
    setLoading(false)
  };

  const updateUserSubscription = async (sid, subscriptionDetail) => {
    setLoading(true)
    if (subscriptionDetail.subscriptionFor && subscriptionDetail.subscriptionFor !== "event") {
      const requestConfig = {
        method: "post",
        url: `${getUrlForBookings()}payments/createStripeSubscriptionCheckoutSessions`,
        headers: {
          "x-access-token": userToken,
        },
        data: {
          bookzySubscriptionId: sid,
          // businessId: userReduxState.userData?._id,
          success_url: `${window.location.origin}/businessprofilesetup/subscription/success`,
          cancel_url: `${window.location.origin}/businessprofilesetup/subscription/cancel`,
        },
      };
      console.log("requestConfig", requestConfig);
      
      const response = await axios(requestConfig);
      if (response.status === 200) {
        if (response.data.statusCode === 403) {
        } else if (response.data.statusCode === 200) {
          let responseData = response.data.data;
          if (responseData.url) {
            window.location.href = responseData.url;
          } else{

          }
        }
      }
    } else {
      const requestConfig = {
        method: "post",
        url: `${getUrlForUsers()}updateUserSubscription?`,
        headers: {
          "x-access-token": userToken,
        },
        data: {
          subscriptionId: sid,
          businessId: userReduxState.userData?._id
        },
      };
      const response = await axios(requestConfig);
      if (response.status === 200) {
        if (response.data.statusCode === 403) {
        } else if (response.data.statusCode === 200) {
          if (userReduxState.businessType === "event") {
            window.location.href = "/businessprofilesetup/subscription/success";
          }
          if (typeof navigation == "function") {
            // navigation("/businessprofilesetup/done");
          }
        }
      }
    }
    setLoading(false)
  };
  const renderLayout = (layoutType = "full", children) => {
    return layoutType == "full" ? (
      <LoadingBackdrop loading={loading}>
        {((location?.state && location.state.hasOwnProperty("origin") && location.state?.origin === "paymentprofile") || userReduxState.businessType !== "event") && (<Box className="min-w-[1024px]">
          <div
            className={
              "h-16 md:h-12 shadow-sm shadow-neutral-200 sm:flex sm:flex-row sm:justify-center sm:items-center"
            }
          >
            <h4
              className={
                "my-8 sm:my-0 md:my-6 mx-32 sm:mx-0 md:mx-16 font-bold text-4xl sm:text-2xl md:text-2xl"
              }
              sx={{ color: "#481B3A" }}
            >
              Choose your plan
            </h4>
          </div>
          {children}
        </Box>)}
      </LoadingBackdrop>
    ) : (
      <LoadingBackdrop loading={loading}>
        {children}
      </LoadingBackdrop>
    );
  }

  React.useEffect(() => {
    if (userReduxState.businessType !== "") {
      setSubscriptionPlan([])
      getSubscriptionPlan();
    }
  }, [userReduxState.businessType]);

  // React.useEffect(() => {
  //   if (window && document) {
  //     const script = document.createElement('script')
  //     const body = document.getElementsByTagName('body')[0]
  //     script.src = 'https://js.stripe.com/v3/pricing-table.js'
  //     script.async = true

  //     const stripe_pricing_table_tag = document.getElementsByTagName('stripe-pricing-table')
  //     if (stripe_pricing_table_tag && stripe_pricing_table_tag.length) {
  //       for (let index = 0; index < stripe_pricing_table_tag.length; index++) {
  //         const element = stripe_pricing_table_tag[index];
  //         element.remove();

  //       }
  //     }

  //     const subscriptionPlans = document.getElementById('subscriptionPlans')
  //     const scriptpricing = document.createElement('stripe-pricing-table')
  //     // scriptpricing.setAttribute('pricing-table-id', 'prctbl_1PkNfRDx1lYwRllZPc6xn0SW');
  //     // scriptpricing.setAttribute('publishable-key', 'pk_live_51JzKMtDx1lYwRllZ7SopvqoyzlVjtwOrFnal03xTL4NHxRG87ZCZ15Si7hpCCjYlaKvrygZy3Zm94XZ7ALItOvZH00Kxb1Fpni');
  //     // scriptpricing.setAttribute('pricing-table-id', 'prctbl_1Pvvk0Dx1lYwRllZkfX9HKe9');
  //     scriptpricing.setAttribute('pricing-table-id', stripePricingTableId[process.env?.REACT_APP_APP_ENV || "dev"][userReduxState.businessType]);
  //     if (process.env?.REACT_APP_APP_ENV && process.env?.REACT_APP_APP_ENV === "prod") {
  //       // prod
  //       scriptpricing.setAttribute('publishable-key', 'pk_live_51JzKMtDx1lYwRllZ7SopvqoyzlVjtwOrFnal03xTL4NHxRG87ZCZ15Si7hpCCjYlaKvrygZy3Zm94XZ7ALItOvZH00Kxb1Fpni');
  //     } else {
  //       // dev
  //       scriptpricing.setAttribute('publishable-key', 'pk_test_51JzKMtDx1lYwRllZz3E8uV5JH029a7V2fIkTcSOCk1Ww4qz7XUC7CmOsHvUVPilicCNxn926gw2aKLD2iuzVykfk00EZWvIdMW');
  //     }
  //     scriptpricing.setAttribute('client-reference-id', userReduxState.userData._id);

  //     // subscriptionPlans.innerHTML = ""
  //     if (userReduxState.businessType !== "event") {
  //       body.appendChild(script)
  //       // body.appendChild(scriptpricing)
  //       subscriptionPlans.appendChild(scriptpricing)
  //     }

  //   }
  // }, [userReduxState.businessType])
  return (renderLayout(location.state?.from === "list" ? "section" : "full",
    (<Container className={"mx-28 lg:mx-28 sm:mx-0 md:mx-4"}>
      <Box
        id="subscriptionPlans"
        sx={(true || userReduxState.businessType === "event") ? {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "start",
          pt: 5,
          gap: 5,
        } : { pt: 5, }}>

        {(true || userReduxState.businessType === "event") && subscriptionPlan && subscriptionPlan.filter((d) => d.isActive === true).map((d, i) => (<Box key={i} className="flex flex-col bg-brown-coffee text-white rounded-3xl p-5 w-50 gap-2">
          <h1 className="font-extrabold text-2xl">{d.title}</h1>
          <p className="text-2xl"><span className="font-bold text-4xl">€{d.price}</span>/mo</p>
          <p className="text-xl text-[#FFFFFF99]">{d.description} </p>
          {d.subscriptionDetail.length && d.subscriptionDetail.map((sd, sdi) => (
            <p className="flex flex-row items-start text-lg gap-3" key={sdi}>
              <svg className="mt-1 w-5" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="white" />
              </svg>
              <p className="flex-1" dangerouslySetInnerHTML={{ __html: sd }}></p>
            </p>
          ))}
          <Button
            sx={{
              color: "common.white",
              backgroundColor: "secondary.mainLight",
              boxShadow: "none",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "secondary.mainLight",
                boxShadow: "none",
              },
              fontWeight: 600,
              borderRadius: 3,
              fontSize: 16,
              mt: 3,
            }}
            variant={"contained"}
            onClick={() => {
              updateUserSubscription(d._id, d);
            }}>
            {(i > 0) ? "Try for Free" : "Start Free "}
          </Button>
        </Box>))}

      </Box>
    </Container>
    ))
  );
  return (
    <LoadingBackdrop loading={loading}>
      {((location?.state && location.state.hasOwnProperty("origin") && location.state?.origin === "paymentprofile") || userReduxState.businessType !== "event") && (<Box className="min-w-[1024px]">
        <div
          className={
            "h-16 md:h-12 shadow-sm shadow-neutral-200 sm:flex sm:flex-row sm:justify-center sm:items-center"
          }
        >
          <h4
            className={
              "my-8 sm:my-0 md:my-6 mx-32 sm:mx-0 md:mx-16 font-bold text-4xl sm:text-2xl md:text-2xl"
            }
            sx={{ color: "#481B3A" }}
          >
            Choose your plan
          </h4>
        </div>
        <Container className={"mx-32 lg:mx-28 sm:mx-0 md:mx-4"}>
          <Box
            id="subscriptionPlans"
            sx={userReduxState.businessType === "event" ? {
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "start",
              pt: 5,
              gap: 5,
            } : { pt: 5, }}>

            {userReduxState.businessType === "event" && subscriptionPlan && subscriptionPlan.map((d, i) => (<Box key={i} className="flex flex-col bg-brown-coffee text-white rounded-3xl p-5 w-80 gap-2">
              <h1 className="font-extrabold text-2xl">{d.title}</h1>
              <p className="text-2xl"><span className="font-bold text-4xl">€{d.price}</span>/mo</p>
              <p className="text-xl text-[#FFFFFF99]">{d.description} </p>
              {d.subscriptionDetail.length && d.subscriptionDetail.map((sd, sdi) => (
                <p className="flex flex-row items-start text-lg gap-3" key={sdi}>
                  <svg className="mt-1 w-5" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28472 18.7782 3.22182C16.7153 1.15892 13.9174 0 11 0ZM9.42857 15.3921L5.5 11.4636L6.74929 10.2143L9.42857 12.8936L15.2507 7.07143L16.5047 8.31757L9.42857 15.3921Z" fill="white" />
                  </svg>
                  <p className="flex-1" dangerouslySetInnerHTML={{ __html: sd }}></p>
                </p>
              ))}
              <Button
                sx={{
                  color: "common.white",
                  backgroundColor: "secondary.mainLight",
                  boxShadow: "none",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "secondary.mainLight",
                    boxShadow: "none",
                  },
                  fontWeight: 600,
                  borderRadius: 3,
                  fontSize: 16,
                  mt: 3,
                }}
                variant={"contained"}
                onClick={() => {
                  updateUserSubscription(d._id, d);
                }}>
                {(i > 0) ? "Try for Free" : "Start Free "}
              </Button>
            </Box>))}

          </Box>
        </Container>
      </Box>)}
    </LoadingBackdrop>
  );
}
